@use '@angular/material' as mat;

@include mat.core();

$primary: (
    50 : #e6e0e7,
    100 : #c1b3c2,
    200 : #98809a,
    300 : #6f4d72,
    400 : #502653,
    500 : #310035,
    600 : #2c0030,
    700 : #250028,
    800 : #1f0022,
    900 : #130016,
    A100 : #e056ff,
    A200 : #d723ff,
    A400 : #c300ef,
    A700 : #af00d5,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$accent: (
    50 : #fcede5,
    100 : #f9d3be,
    200 : #f5b593,
    300 : #f09767,
    400 : #ed8147,
    500 : #ea6b26,
    600 : #e76322,
    700 : #e4581c,
    800 : #e14e17,
    900 : #db3c0d,
    A100 : #ffffff,
    A200 : #ffdcd4,
    A400 : #ffb2a1,
    A700 : #ff9e87,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$card-limbersoftware-primary: mat.define-palette($primary);
$card-limbersoftware-accent: mat.define-palette($accent);
$card-limbersoftware-warn: mat.define-palette(mat.$red-palette);
$card-limbersoftware-typography: mat.define-typography-config(
    $font-family: '"Google Sans", sans-serif',
    $body-1: mat.define-typography-level(
        $font-family: '"Google Sans", sans-serif',
        $font-weight: 400,
        $font-size: 14px,
        $line-height: 1.5,
        $letter-spacing: normal,
    ),
    $button: mat.define-typography-level(
        $font-family: '"Google Sans", sans-serif',
        $font-weight: 500,
        $font-size: 14px,
        $line-height: 1.5,
        $letter-spacing: normal,
    ),
);

$card-limbersoftware-theme: mat.define-light-theme((
    color: (
        primary: $card-limbersoftware-primary,
        accent: $card-limbersoftware-accent,
        warn: $card-limbersoftware-warn,
    ),
    density: -2,
));

$card-limbersoftware-theme-dark: mat.define-dark-theme((
    color: (
        primary: $card-limbersoftware-accent,
        accent: $card-limbersoftware-primary,
        warn: $card-limbersoftware-warn,
    ),
    density: -2,
));


@include mat.all-component-themes($card-limbersoftware-theme);

@include mat.all-component-typographies($card-limbersoftware-typography);

h1, h2, h3, h4, h5 {
    margin: 0 !important;
}

mat-card-header {
    margin-top: 8px !important;
}

.mat-calendar-content {
    height: 300px !important;
}

:root {
    --mdc-protected-button-label-text-size: 15px;
    --mdc-protected-button-container-height: 36px;
    --mdc-outlined-button-label-text-size: 15px;
    --mdc-outlined-button-container-height: 36px;
    --mdc-filled-button-label-text-size: 15px;
    --mdc-filled-button-container-height: 36px;
}

.grid-tabela-assentos {
    @include mat.all-component-densities(-3);
    .mat-mdc-text-field-wrapper {
        padding-left: 6px;
        padding-right: 6px;
    }
}

.seat-toChange {
    background-color: skyblue;
}

.seat-bloqueado {
    * {
        pointer-events: none !important;
    }
    .mdc-text-field {
        background-color: unset !important;
    }
}
