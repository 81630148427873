
$primary-color: #310035; // roxo
$secondary-color: #ff7c34; // laranja

$warn-color: #ff9800;
$error-color: #f44336;
$success-color: #4caf50;
$info-color: #2196f3;


$cinza-claro: #f6f6f6;
$cinza-escuro: #dedede;


// VENDAS - STATUS
$reservado: #2e54f1;
$inutilizado: #797979;
$aprovado: #39f114;
$cancelado: #F11414;
$pedido-canc: #FF8400;
$pedido-desc: #FFE700;


.white-color {
    color: white !important;
}

.warn-color {
    color: $warn-color !important;
}

.error-color {
    color: $error-color !important;
}

.success-color {
    color: $success-color !important;
}


.border-warn {
    border-color: $warn-color !important;
}
.border-success {
    border-color: $success-color !important;
}
.border-error {
    border-color: $error-color !important;
}
.border-info {
    border-color: $info-color !important;
}



.info-bg {
    background: $info-color !important;
}

.warn-bg {
    background: $warn-color !important;
}

.error-bg {
    background: $error-color !important;
}

.success-bg {
    background: $success-color !important;
}

.info-color {
    color: $info-color !important;
}



.primary-text {
    color: $primary-color !important;
}

.secondary-text {
    color: $secondary-color !important;
}

.alert-text {
    color: $warn-color !important;
}



.bg-reservado {
    background-color: transparentize($reservado, .6) !important;
}

.bg-reserva-n-concluida {
    background-color: transparentize($inutilizado, .6) !important;
}

.bg-aprovada {
    background-color: transparentize($aprovado, .7) !important;
}

.bg-cancelada {
    background-color: transparentize($cancelado, .6) !important;
}

.bg-pedido-cancelamento {
    background-color: transparentize($pedido-canc, .6) !important;
}

.bg-pedido-cupom-desconto {
    background-color: transparentize($pedido-desc, .6) !important;
}

.color-reservado {
    color: darken($reservado, .1) !important;
}

.color-reserva-n-concluida {
    color: darken($inutilizado, .1) !important;
}

.color-aprovada {
    color: darken($aprovado, .1) !important;
}

.color-cancelada {
    color: darken($cancelado, .1) !important;
}

.color-pedido-cancelamento {
    color: darken($pedido-canc, .1) !important;
}

.color-pedido-cupom-desconto {
    color: darken($pedido-desc, .1) !important;
}

.lightpurple-bg {
    background: lighten($primary-color, 85%) !important;
}

.purple-bg {
    background-color: $primary-color !important;
}

.lightorange-bg {
    background: lighten($secondary-color, 70%) !important;
}

.secondary-bg {
    background: $secondary-color;
}
