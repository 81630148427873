// Arquivo para setar estilos específicos para cada componente
@use 'colors';

pages {
    width: 100%;
}

new-or-edit-bilhete {
    .paises {
        mat-form-field {
            width: 100% !important;
        }
    }
}

historico {
    .mat-stepper-vertical .mat-step-label {
        width: 100% !important;
    }

    #error {
        mat-step-header {
            background-color: colors.$error-color !important;
        }

        mat-step-label {
            color: white;
        }
    }

    #sucess {
        mat-step-header {
            background-color: colors.$success-color !important;
        }

        mat-step-label {
            color: white;
        }
    }
}

toolbar-menu {
    .mat-drawer-backdrop.mat-drawer-shown {
        background-color: rgba(0, 0, 0, 0);
    }

    .mat-mdc-list .mat-mdc-list-item .mdc-list-item__content {
        height: initial;
    }

    .mat-drawer:not(.mat-drawer-side) {
        -webkit-box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.24);
        -moz-box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.24);
        box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.24);
    }

    .mat-mdc-card {
        padding: 6px 16px 0 16px !important;
    }

    .mat-drawer-container {
        display: initial;
    }
}

new-or-edit-vendas {
    .btns-action {
        margin-bottom: -15px;
        margin-left: -15px;
        margin-right: -15px;

        button {
            border: 1px solid #721294;
            width: 200px !important;
            text-align: center;
            border-width: inherit;
            font-weight: 600;
            margin: 15px 15px 15px 15px;
        }
    }
}

new-or-edit-bilhete, new-or-edit-ec-config, new-or-edit-modo-pagamento, new-or-edit-categoria, new-or-edit-estabelecimento, new-or-edit-esuario {
    table {
        th {
            padding: 10px;
        }
    }
}

image-cropper img {
    margin: auto;
}

