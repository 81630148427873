@use '@angular/material' as mat;
@use 'colors' as colors;

// DIMINUI O TAMANHO DOS SLIDERS
.mat-mdc-slider .mdc-slider__track {
    height: var(--mdc-slider-inactive-track-height, 2px) !important;
}
.mat-mdc-slider .mdc-slider__thumb-knob {
    width: var(--mdc-slider-handle-width, 15px) !important;
    height: var(--mdc-slider-handle-height, 15px) !important;
    border-width: calc(var(--mdc-slider-handle-height, 15px) / 2) calc(var(--mdc-slider-handle-width, 15px) / 2) !important;
}

.custom-snackbar-container {
    .mdc-snackbar__surface {
        overflow: hidden !important;
        padding: 0 !important;
    }
    .mat-mdc-snack-bar-label.mdc-snackbar__label {
        padding: 0 0 0 0 !important;
        background-color: #fff !important;
    }
}

.mat-mdc-button>.mat-icon {
    margin-right: revert !important;
}

.mdc-notched-outline__notch {
    border-right: none;
}

@include mat.slider-density(-2);

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex  {
    label.mat-mdc-floating-label {
        // Esse funciona mas fica bem estupido em textarea e inputs maiores
        //     transition-property: all;
        //     &:not(.mdc-floating-label--float-above) {
        //         top: 50%;
        //     }
        // Esse funciona mas precisa setar manualmente o top
        top: 1.5rem;
        &.mdc-floating-label--float-above {
            top: 1.7rem;
        }
    }
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    display: flex;
    align-items: center;
}

.mdc-data-table__header-cell {
    font-size: var(--mdc-typography-subtitle2-font-size, 12px);
}

.mat-select-panel mat-option.mat-mdc-option {
    height: unset;
}
// Isso serve para que os itens, dentro de um optGroup ocupem 100% do espaço dentro do select
.mat-mdc-optgroup-label .mdc-list-item__primary-text {
    width: 100%;
}

.no-padding-expansion-panel {
    .mat-expansion-panel-header, .mat-expansion-panel-body {
        padding: 0 !important;
    }
}

.mat-mdc-select-panel {
    max-width: none !important;
}

.mat-expansion-panel-header {
    height: auto !important;
    min-height: 48px !important;
}

.mat-mdc-outlined-button {
    border: 1px solid #cfcfcf !important;
}

//.mat-mdc-menu-content {
//    padding-bottom: 0 !important;
//}

.mat-mdc-menu-panel {
    min-width: 320px !important;
    overflow: hidden !important;
    background: #310035 !important;

    .mat-mdc-menu-item {
        &:hover {
            background: rgba(255, 255, 255, 0.2) !important;
        }

        color: white !important;

        mat-icon {
            color: white !important;
        }

        .mdc-list-item__primary-text {
            color: white !important;
        }
    }
}

.mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-suffix,
.mat-mdc-form-field-prefix {
    top: 0 !important;
    display: inline-flex;
    align-items: center;
}
.mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field-suffix {
    margin-left: 5px;
    margin-right: 5px;
}
.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-prefix {
    margin-right: 5px;
    margin-left: 5px;
}

// Cor padrão da borda do mat-form-field-outline
.mdc-text-field--outlined:not(.mdc-text-field--invalid, .mdc-text-field--disabled, .mdc-text-field--focused) {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
        border-color: var(--mdc-form-field-border ,rgba(colors.$primary-color, 0.12))!important;
    }
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)  {
    .mat-mdc-form-field-infix {
        display: flex;
        align-items: flex-end;
        padding-bottom: 5px;
    }

    label.mat-mdc-floating-label {
        display: block;
        top: 1.5rem;
        &.mdc-floating-label--float-above {
            top: 1.7rem;
        }
    }
}

.fix-underline-mat-form-field {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: transparent;
    }
}

.mat-chip-pointer {
    cursor: pointer !important;

    * {
        cursor: inherit !important;
    }
}

.mat-mdc-chip:not(.mat-chip-border) .mdc-evolution-chip__action {
    &::after, &::before {
        border: none;
    }
}

// Restauras os padding do mat-card como era antes

mat-card {
    padding: 1rem;
}

:root {
    --mdc-elevated-card-container-shape: 4px;
}

.mat-mdc-dialog-surface {
    padding: 24px;
}

.no-padding-dialog {
    .mat-mdc-dialog-surface {
        padding: 0;
    }
}

.no-padding-bottom-dialog {
    .mat-mdc-dialog-surface {
        padding-bottom: 0;
    }
}

.mat-mdc-progress-spinner  {
    display: block;
}

.tabs-with-no-min-width {
    .mdc-tab {
        min-width: 0 !important;
    }
}

.tabs-with-no-labels {

    .mat-mdc-tab-body-wrapper {
        height: 100%;
    }

    .mat-mdc-tab-header {
        border: none !important;
    }
    .mat-mdc-tab-labels {
        display: none !important;
    }
}

.mat-mdc-tab-group {
    height: inherit;
}

.mat-mdc-tab-body-wrapper {
    height: inherit;
}

.mat-mdc-tab-body-content {
    height: max-content !important;
    overflow: hidden !important;
}

.mat-mdc-tab-header-pagination.mat-mdc-tab-header-pagination-disabled {
    display: none !important;
}

.tab-body-scroll {
    .mat-mdc-tab-body.mat-mdc-tab-body-active {
        position: absolute;
    }
    .mat-mdc-tab-body-wrapper {
        overflow-y: auto;
    }
}

.mat-mdc-dialog-actions {
    justify-content: flex-end;
}

.mat-mdc-dialog-content .mat-mdc-form-field {
    width: 100%;
}

.mat-expansion-indicator::after {
    border-color: transparentize(colors.$primary-color, 0.5)
}
